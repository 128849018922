import { gql } from '@apollo/client'

export const EMPLOYER_FRAGMENT = gql`
	fragment employerFragment on Employer {
		id
		email
		firstName
		lastName
		avatarUrl
	}
`

export const EMERGENCY_CONTACT_FRAGMENT = gql`
	fragment emergencyContactFragment on EmergencyContact {
		id
		contactName
		contactPhone
		contactRelationship
	}
`

export const WORKER_ADDRESS_FRAGMENT = gql`
	fragment workerAddressFragment on WorkerAddress {
		id
		zipcode
		street
		city
		state
		country
		aptNumber
	}
`

export const WORKER_FRAGMENT = gql`
	fragment workerFragment on Worker {
		noSms
		isSmsOptOut
		id
		email
		confirmedWorkedLastWeek
		firstName
		lastName
		avatarUrl
		phone
		phoneVerifiedAt
		workAuthorized
		maidenName
		availableStartDate
		timeOffRequested
		shiftRequested
		remainingExpirationTime
		changePasswordRequired
		resumes {
			id
			filename
			fileUrl
			thumbnails
			contentType
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
		company {
			companyConfig {
				adpUrl
				companyId
				oneSignalAppId
			}
		}
		recruiter {
			email
			phone
			id
			title
			name
		}
		avatarUrl
	}
`

export const LOCATION_FRAGMENT = gql`
	fragment locationFragment on Location {
		id
		address
		addressState
		city
		country
		zipcode
	}
`

export const JOB_FRAGMENT = gql`
	fragment jobFragment on Job {
		location {
			region
			city
			zipcode
			addressState
		}
		seoPath
		slug
		jobApplicant {
			currentWorkerAssignment {
				createdAt
				endDate
				id
				startDate
				status
				updatedAt
				client {
					name
					id
				}
			}
			applicationProcess
			createdAt
			id
			job {
				id
			}
			status
			submittal {
				client {
					id
					name
				}
				id
				jobId
				status
			}
			timeOffEndDate
			timeOffStartDate
			updatedAt
		}
		locationImage {
			thumbnailImage {
				imageUrls(size: ["100x100"])
			}
			webImage {
				fileUrl
			}
		}
		customStartDate
		isDisliked
		isLiked
		applicantCount
		createdAt
		description
		discipline
		duration
		exclusiveJob
		externalJobId
		externalFriendlyId
		externalUpdatedAt
		facilityType
		hospital
		favoriteCount
		frequency
		id
		jobType
		externalCreatedAt
		matchingPercentage
		minWeeklyPayAmount
		shifts
		skillLongName
		specialty
		startDate
		status
		title
		updatedAt
		isAsap
		viewCount
		weeklyPayAmount
		slotsNumber
		hoursPerWeek
		minPayAmount
		payAmount
		isHotJob
		isExclusiveJob
	}
`

export const COMPANY_FRAGMENT = gql`
	fragment companyFragment on Company {
		address
		addressState
		city
		country
		description
		id
		mainContactEmail
		mainContactName
		name
		phone
		zip
	}
`

export const WORKER_CREDENTIAL_FRAGMENT = gql`
	fragment workerCredentialFragment on WorkerCredential {
		company {
			...companyFragment
		}
		createdAt
		expirationDate
		id
		name
		status
		worker {
			...workerFragment
		}
	}
	${WORKER_FRAGMENT}
	${COMPANY_FRAGMENT}
`

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
	fragment workingAuthorizationFragment on WorkingAuthorization {
		id
		dateOfBirth
		socialSecurityNumber
	}
`

export const WORK_EXPERIENCE_FRAGMENT = gql`
	fragment workExperienceFragment on WorkExperience {
		id
		facilityName
		position
		unitSpecialty
		floatSpecialty
		facilityType
		patientType
		city
		state
		country
		travelAssignment
		agency
		reasonForLeaving
		dutiesPerformed
		startDate
		endDate
		currentlyEmployed
		assignmentType
		supervisor
		mayContactSupervisor
		nurseRatio
		patientRatio
		bedsInUnit
		birthsPerMonth
		numberSuites
		dailyCensus
		chartingSoftwareUsed
	}
`

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
	fragment salesforceIntegrationFragment on SalesforceIntegration {
		id
		companyId
		businessDay
		weekending
	}
`

export const WORKED_TIMESHEET_FRAGMENT = gql`
	fragment workedTimesheetFragment on WorkedTimesheet {
		id
	}
`

export const ONBOARDING_PART_FRAGMENT = gql`
	fragment onboardingPartFragment on OnboardingPart {
		id
		name
		description
	}
`
export const DIRECT_UPLOAD_FRAGMENT = gql`
	fragment directUploadFragment on DirectUpload {
		blobId
		headers
		preSignedUrl
		signedBlobId
		url
	}
`

export const WORKER_CERTIFICATION_FRAGMENT = gql`
	fragment workerCertificationFragment on WorkerCertification {
		id
		title
		licenseDiscipline
		licenseState
		licenseNumber
		compactLicense
		expirationDate
		certificationType
		createdAt
		licenseImages {
			id
			contentType
			filename
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
	}
`

export const WORKER_REFERENCE_FRAGMENT = gql`
	fragment workerReferenceFragment on WorkerReference {
		id
		facilityName
		contactFullName
		contactFirstName
		contactLastName
		contactEmail
		contactPhone
		jobTitle
		refType
		relationship
		salutation
		state
		workedFrom
		workedTo
		city
	}
`
export const IMAGE_FILE_FRAGMENT = gql`
	fragment imageFileFragment on File {
		id
		fileUrl
		contentType
	}
`

export const FILE_FRAGMENT = gql`
	fragment fileFragment on File {
		id
		filename
		fileUrl
		contentType
		blobId
		imageUrls(size: ["100x100", "200x200", "500x500"])
	}
`

export const WORKER_EDUCATION_FRAGMENT = gql`
	fragment workerEducationFragment on WorkerEducation {
		id
		schoolName
		major
		state
		city
		country
		degreeName
		degreeDate
	}
`

export const LICENSE_IMAGES_FRAGMENT = gql`
	fragment licenseImagesFragment on File {
		id
		fileUrl
		contentType
	}
`

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
	fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
		id
		workerCertifications {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
	fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
		id
		workerReferences {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
	fragment workerEducationOverviewFragment on WorkerEducationOverview {
		id
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const WORK_EXPERIENCE_OVERVIEW_FRAMENT = gql`
	fragment workExperienceOverview on WorkExperienceOverview {
		id
		discipline
		primarySpecialty
		secondarySpecialty
		yearsOfExperience
		workExperiences {
			...workExperienceFragment
		}
	}

	${WORK_EXPERIENCE_FRAGMENT}
`

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on UserTermsOfService {
			id
			agreedAt
			termsOfService {
				id
				url
				version
			}
		}
		... on ResumeOnboarding {
			id
			worker {
				resumes {
					blobId
					id
					filename
					fileUrl
					contentType
					thumbnails
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
		}
		... on SpecialtyOnboarding {
			id
			workerSpecialties {
				checklistFile {
					blobId
					contentType
					filename
					fileUrl
					id
				}
				specialtyType

				category
				id
				isPrimary
				completedChecklist
				specialty
				status
				isExpired
				category
				specialtyLongName
				workerSkillChecklistSections {
					id
					name
					workerSpecialty {
						status
					}
					workerSkillChecklists {
						frequency
						proficiency
						question
						id
					}
				}
			}
		}
		... on WorkingPreferredLocationOnboarding {
			id
			worker {
				id
				availableStartDate
			}
			workingPreferredLocations {
				id
				preferredWorkingState
				preferredWorkingCity
			}
		}

		... on WorkerReferenceOnboarding {
			id
			workerReferences {
				id
				facilityName
				contactEmail
				contactFullName
				contactFirstName
				contactLastName
				contactPhone
				jobTitle
				refType
				relationship
				salutation
				state
				city
				workedFrom
				workedTo
			}
		}
	}
`

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		onboardingPart {
			id
			name
		}
		integration {
			...workerOnboardingPartIntegrationFragment
		}
	}

	${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const REFERRED_TRAVELER_FRAGMENT = gql`
	fragment referredTravelerFragment on ReferredTraveler {
		id
		firstName
		lastName
		email
		phone
		relationship
		discipline
	}
`

export const WORKER_ASSIGNMENT_FRAGMENT = gql`
	fragment workerAssignmentFragment on WorkerAssignment {
		id
		startDate
		availableToExtend
		endDate
		status
		client {
			id
			name
		}
		job {
			...jobFragment
		}
		jobApplicant {
			applyDate
			createdAt
			id
			note
			source
			status
			submittal {
				externalUpdatedAt
				id
				jobId
				status
				submittalDate
			}
			timeOffEndDate
			timeOffStartDate
			updatedAt
			applicationProcess
		}
	}
	${JOB_FRAGMENT}
`

export const WORKER_AUTO_SUBMISSION_FRAGMENT = gql`
	fragment workerAutoSubmissionFragment on WorkerAutoSubmission {
		address
		address2
		additionalRequirements
		city
		covidStatus
		dateOfBirth
		expiredAt
		externalId
		facilityTypes
		id
		isEnabled
		preferredWorkingLocations
		shifts
		ssnLast4
		state
		weeklyPayAmount
		zipCode
		desiredDistance
	}
`
